import styled from "styled-components";

export const WorkAnimation = styled.div`
animation: 2s ease-out 0s 1 slideInFromLeft;
top:20%;
position: absolute;
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
}
100% {
    transform: translateX(0px);
}
`

export const WorkSectionContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
`

export const Card = styled.div`
position: relative;
min-width: 340px;
height: 550px;
box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    2px 2px 10px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
border-radius: 15px;
margin: 40px;
transition: 0.5s;
`
export const CardBox = styled.div`
position: absolute;
top: 20px;
left: 20px;
right: 20px;
display: flex;
justify-content: center;
align-items: center;
overflow: hidden;
transition: 0.5s;
// border-radius:8px;

&:hover {
    transform: translateY(-25px);
}

`

export const CardContent = styled.div`
padding: 20px;
text-align: center;
a{
    position: relative;
        display: inline-block;
        padding: 8px 20px;
        background: black;
        border-radius: 5px;
        text-decoration: none;
        color: white;
        margin-top: 20px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
        transition: 0.5s;
}
a:hover{
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
        background: #f0f2f5;
        color: #18130E;
        letter-spacing:3px;
}
p{
        font-weight: 300;
        color: #18130E;
        z-index: 1;
        transition: 0.5s;
        margin:6px;
        text-align:center;
}
img{
    width:300px;
    border-radius:6px;
    height:150px;

}
h3{
    font-size: 1.2rem;
        color: #18130E;
        z-index: 1;
        transition: 0.5s;
        margin-bottom: 15px;
}
ul.project-technology-list{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    margin: 25px 0px 10px;
    padding: 0px;
    list-style: none;
}
h5{
    font-size:14px;
}
li {
    margin: 0px 0px 8px 24px;
    font-size:12px;
}
`

export const CardBar = styled.div`
display: block;
position: absolute;
height: 40px;
top:0;
width: 100%;
color: #18130E;
z-index: 10;
border-radius: 8px;
h2{
    font-size: 1.4rem;
        color: #18130E;
        z-index: 1;
        transition: 0.5s;
        padding:8px;
        top:0;

}
`