import React, { useState, useEffect } from 'react';
import {
    TopBar,
    TopBarInner,
    Logo,
    FlexBox,
    Button,
    Menu,
    AnimationButton
} from './header.styles';
import { NavLink } from 'react-router-dom';
import Pdf from '../../assets/resume.pdf';



const Header = () => {
    const [showNav, setShowNav] = useState(false);
    const [scrolling, setScrolling] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);
    const [className, setClassName] = useState(null);

    useEffect(() => {
        setShowNav(false);
    }, [setShowNav]);

    useEffect(() => {
        function onScroll() {
            let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
            if (currentPosition > scrollTop) {
                // downscroll code
                setScrolling(false);
                setClassName('shadow bg-white rounded');
            } else {
                // upscroll code
                setScrolling(true);
                setClassName('shadow bg-white rounded');
            }
            if(window.pageYOffset == 0){
                setClassName('')
            }
            setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);


    function openNav() {
        setShowNav(true);
    }

    function closeNav() {
        setShowNav(false);
    }
    return (
        <>
            <TopBar className={className}>
                <TopBarInner>
                    <Logo to='/'>
                        <h1>ganeshkuikel</h1>
                    </Logo>
                    <FlexBox>
                        <Button
                            button='resume'
                            target="_blank"
                            to={Pdf}
                            // download
                            title='Download Resume'
                        >
                            Resume
                        </Button>
                        <div
                            className="toggle"
                            onClick={() => openNav()}>
                            <img src='/bar.png' width='40px' />
                        </div>
                    </FlexBox>
                </TopBarInner>
            </TopBar>
            <Menu id={`${showNav ? 'myNav' : ''}`}>
                <a className="closebtn" onClick={() => closeNav()}>&times;</a>
                <ul>
                    <li onClick={() => setShowNav(false)}><AnimationButton><NavLink exact activeClassName="active" to='/'>Home</NavLink></AnimationButton></li>
                    <li onClick={() => setShowNav(false)}><AnimationButton><NavLink activeClassName="active" to='/about'>About</NavLink></AnimationButton></li>
                    {/* <li onClick={() => setShowNav(false)}><AnimationButton><a href="#">Experience</a></AnimationButton></li> */}
                    <li onClick={() => setShowNav(false)}><AnimationButton><NavLink activeClassName="active" to='/work'>Work</NavLink></AnimationButton></li>
                    <li onClick={() => setShowNav(false)}><AnimationButton><NavLink activeClassName="active" to='/contact'>Contact</NavLink></AnimationButton></li>
                    <li onClick={() => setShowNav(false)}>
                        <a
                            button='resume'
                            target="_blank"
                            href={Pdf}
                            title='Download Resume'
                            className='resume-mobile'
                        >
                            Resume
                        </a>
                    </li>
                </ul>
            </Menu>
        </>

    )
};

export default Header;