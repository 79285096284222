import styled from "styled-components";


export const ContactImageContainer = styled.div`
position: absolute;
top: 0;
right: 0;
left: 0;
bottom: 0;
background:linear-gradient(0deg, rgba(113, 68, 56, 0.28),rgba(113, 68, 56, 0.28)), url(https://free4kwallpapers.com/uploads/wallpaper/simple-life---coffee-wallpaper-1024x768-wallpaper.jpg) ;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
width:100vw;
`
export const ContactContent = styled.div`
position: absolute;
top: 20%;
right: 0;
left: 0;
bottom: 0;  
max-width: 600px;
margin: 0px auto 100px;
text-align: center;
animation: 1s ease-out 0s 1 slideInFromTop;
@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
}
100% {
    transform: translateY(0px);
}
`
export const ContactTitle = styled.h2`
font-size: clamp(40px, 5vw, 60px);
margin: 0px 0px 10px;
font-weight: 600;
line-height: 1.1;
`

export const ContactBody = styled.div`
padding:20px;
p{
    font-size:22px;
    font-weight:600;
}
.contact-list{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        z-index: 2;
        margin: 25px 0px 10px;
        padding: 0px;
        list-style: none;
}
ul span{
    margin-left:8px !important;
    padding-top:3px !important;
}
`

export const HelloSection = styled.a`
  margin-top: 10px !important;
padding:6px;
font-size: 15px !important;
text-transform: uppercase;
border:2px solid #18130E;
background: transparent;
-webkit-transition: all 0.3s ease;
-moz-transition: all 0.3s ease;
-ms-transition: all 0.3s ease;
-o-transition: all 0.3s ease;
transition: all 0.3s ease-in-out;
text-decoration:none;
color:#18130E;
border-radius:2px;

&:hover{
background:#18130E;
color:#fff;
-webkit-transform: translateY(-6px) !important;
transform: translateY(-6px) !important;
}
&:before {
content: "";
position: absolute;
left: 0;
bottom: -5px;
width: 0;
height: 2px;
transition: .3s;
}
`

export const SocialMedia = styled.div`
margin-left: 10%;
margin-right: 3%;
margin-top:10%;
z-index: 1;
display: flex;
flex-flow: row wrap;
justify-content: space-around;
`