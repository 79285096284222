import React from 'react';
import {
    ContactImageContainer,
    ContactContent,
    ContactTitle,
    ContactBody,
    SocialMedia,
    HelloSection

} from './contact-section.styles';
import { Link } from 'react-router-dom';
import Navigation from '../common/common-component';
const ContactSection = () => {
    const email = 'mailto:ganeshkuikel66@gmail.com'
    return (
        <>

            {/* <ContactImageContainer> */}
            <Navigation
                    previousName='Projects'
                    nextName='Home'
                    prevLink='/work'
                    nextLink='/'
                />
            <ContactContent>
                <ContactTitle>
                    Get In Touch
                </ContactTitle>
                <ContactBody>
                    <p>Whether you have any query with me or want to hire me as a Freelancer. Let's Get connected.</p>
                    {/* <ul className='contact-list'>
                            <li><i className="fas fa-envelope"></i><span>ganeshkuikel66@gmail.com</span></li>
                        </ul> */}
                </ContactBody>
                <HelloSection href={email}>Say Hello</HelloSection>
                <h2 className='mt-5'>SOCIAL MEDIA</h2>

                <SocialMedia>
                    <a href='https://www.linkedin.com/in/ganesh-kuikel-a88214164/' target='_blank'>
                        <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/7/76/Font_Awesome_5_brands_linkedin.svg/768px-Font_Awesome_5_brands_linkedin.svg.png' width='50px' />
                    </a>
                    <a href='https://www.facebook.com/ganesh.kuikel.1/' target='_blank'>
                        <img src='https://image.flaticon.com/icons/png/512/59/59439.png' width='50px' />
                    </a>
                    <a href='https://www.instagram.com/ganesh.kuikel/' target='_blank'>
                        <img src='https://sureshmurali.github.io/insta.8dcf5a6e.svg' width='50px' />
                    </a>
                    <a href='https://github.com/ganeshkuikel/' target='_blank'>
                        <img src='https://sureshmurali.github.io/git.c871808a.svg' width='50px' />
                    </a>
                </SocialMedia>
            </ContactContent>
            {/* </ContactImageContainer> */}
        </>
    )
};

export default ContactSection;