import styled from 'styled-components';
import { Link } from 'react-router-dom';


// Header
export const TopBar = styled.div`
position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: all .3s ease;
    z-index:10;
`;
export const TopBarInner = styled.div`
max-width: 1200px;
margin: 0 auto;
height: auto;
clear: both;
display: flex;
align-items: center;
justify-content: space-between;
    padding: 25px 50px;
    transition: all .3s ease;
`;

export const Logo = styled(Link)`
font-family:Signature;
font-size:12px;
text-decoration:none;
color:#18130E;
@media (min-width: 769px) {
  h1{
  margin-left:28px;
  font-size:28px;
  }
}
@media (max-width: 320px) {
  h1{
  font-size:14px;
  left:2px !important;
  }
}
cursor:pointer;
transition-delay: 100ms;
&:hover{
  color:#18130E !important;
}
`;

export const Menu = styled.div`
height: 100%;
  width: 0;
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
  text-align: center;
  ul{
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
  }
  ul li {
    padding: 12px;
    color: #818181;
    display: block;
    margin:10px;
  }
  ul li a.active {
    border-bottom: 2px solid #fff;
    border-height: calc(100% + 3px);
  }
  ul li a{
    padding-left:24px;
    padding-right:24px;
    text-decoration: none;
    font-size: 36px;
    transition: 0.3s;
    color:#fff;
    margin:10px;
  }
  a.closebtn{
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    color:#fff;
    cursor:pointer;
    padding:10px;
  }
  ul a {
    position: relative;
 }
 @media(min-width:767px){
  ul a.resume-mobile {
    display:none;
  }
 }
 ul a.resume-mobile {
   display:block
  padding-left:24px;
  padding-right:24px;
  text-decoration: none;
  font-size: 36px;
  transition: 0.3s;
  color:#fff;
  margin:10px;
  cursor:pointer;
}
`



// Container

export const Button = styled(Link)`
  margin-top: 0;
  padding:6px;
    font-size: 15px !important;
    text-transform: uppercase;
    border:2px solid #18130E;
    background: transparent;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease-in-out;
    text-decoration:none;
    color:#18130E;
    border-radius:2px;

  &:hover{
  background:#18130E;
  color:#fff;
  -webkit-transform: translateY(-6px) !important;
    transform: translateY(-6px) !important;
}
&:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  background: currentcolor;
  height: 2px;
  transition: .3s;
}
@media(max-width:767px){
    display: ${props => 
      props.button == "resume" ? "none;" : ""};
}
`

export const FlexBox = styled.div`
display: flex;
height:40px;
.toggle
{
  cursor: pointer;
  margin-left: 24px;
}
`

export const AnimationButton = styled.div`
a:before{
   content: '';
   position: absolute;
   width: calc(100% + 3px);
   height: calc(100% + 3px);
   top: calc(2px/-1);
   left: calc(2px/-1);
   background: linear-gradient(to right, #fff 0%, #fff 100%), linear-gradient(to top, #fff 50%, transparent 50%), linear-gradient(to top, #fff 50%, transparent 50%), linear-gradient(to right, #fff 0%, #fff 100%), linear-gradient(to left, #fff 0%, #fff 100%);
   background-size: 100% 4px, 4px 200%, 4px 200%, 0% 4px, 0% 4px;
   background-position: 50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
   background-repeat: no-repeat, no-repeat;
   transition: transform 0.2s ease-in-out, background-position 0.2s ease-in-out, background-size 0.2s ease-in-out;
   transform: scaleX(0) rotate(180deg);
   transition-delay: 0.4s, 0.2s, 0s;
}
a:hover:before {
   background-size: 200% 4px, 4px 400%, 4px 400%, 55% 4px, 55% 4px;
   background-position: 50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
   transform: scaleX(1) rotate(180deg);
   transition-delay: 0s, 0.2s, 0.4s;
}
`;