import React from 'react';
import {
    ToggleButtonNavigation,
    LabelRight,
    LabelLeft,
    Text
} from './common-styles';
const Navigation = ({previousName, nextName,prevLink,nextLink}) => {
    return (
        <ToggleButtonNavigation>
            <LabelLeft to={prevLink} title={previousName}>
                <i class="fas fa-angle-left"></i>
            </LabelLeft>
            <LabelRight to={nextLink} title={nextName}>
                <i class="fas fa-angle-right"></i>
            </LabelRight>
        </ToggleButtonNavigation>
    )
};
export default Navigation;