import React from 'react';

const SkillsSet = ({ skill, icon }) => {
    return (
        <div className='col-md-6 p-3'>
            {icon}
            {skill}
        </div>
    )
};

export default SkillsSet;