import { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import "./App.css";
import AboutSection from './components/about-section/about-section-component';
import ContactSection from "./components/contact-section/contact-section-component";
import Header from './components/header-section/header-component';
import HomeSection from "./components/home-section/home-section.component";
import WorkSection from './components/work-section/work-section-component';
function App() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000)
  }, [])
  return (
    <>
      {
        loading ? <div className='pre-loader'>
          <img className='text-center' src='/profile.png' width='120px' />
          {/* <h1 className='pre-loader-text d-block'>Ganesh Kuikel</h1></> */}
          <img className='text-center d-block mt-1' src='/signature.png' width='140px'/>
          </div>
          :
          <>
            <Header />
            {/* <SwitchArrow /> */}
            <Switch location={location} key={location}>
              <Route exact path='/' component={HomeSection} />
              <Route exact path='/about' component={AboutSection} />
              <Route exact path='/work' component={WorkSection} />
              <Route exact path='/contact' component={ContactSection} />
            </Switch>
          </>
      }
    </>
  );
}


export default App;
