import React from 'react';

import {
    Card,
    CardBox,
    CardContent,
    CardBar
} from './work-section.styles';
const WorkCard = ({ title, detail, link, image, techList = [] }) => {
    return (
        <Card>
            <CardBox>
                <CardContent>
                    <h3>{title}</h3>
                    <img src={image} />
                    {
                        link ?
                            <a href={link} target={link === '#' ? '' : '_blank'}>Visit</a>
                            : ''
                    }
                    <p>{detail}</p>
                    <strong className='mt-2'>Tech Used:</strong>
                    <ul className='project-technology-list'>
                        {techList.map((tech, key) =>
                            <li key={key}><i className="fas fa-check-square"></i> {tech}</li>
                        )
                        }
                    </ul>
                </CardContent>
            </CardBox>
        </Card >
    )
};

export default WorkCard;