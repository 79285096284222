import styled from "styled-components";

export const AboutMeSection = styled.div`
position: absolute;
top: 20%;
right: 0;
left: 0;
bottom: 0;
`

export const InnerSection = styled.div`
width: 100%;
height: auto;
clear: both;
display: flex;
align-items: center;

@media (max-width: 1040px) {
    flex-direction: column;
}
`

export const LeftSection = styled.div`

`

export const RightSection = styled.div`
margin-top:4rem;
padding:16px !important;
`
export const Border = styled.div`
width: 12rem !important;
height: 0.25rem;
background-color: #18130E !important;
`
export const MyContent = styled.div`
font-size: 18px;
text-align:justify;
`

export const AnimationContent = styled.div`
position: relative;
animation: 1.5s cubic-bezier(0, 0.2, 0.35, 0.99) 0s 1 normal forwards running slideInFromLeft;
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-500px);
}
100% {
    transform: translateX(0px);
}
`

export const AboutImage = styled.img`
@media(min-width:1025px){
    height:400px;
    width:400px;
}
height:350px;
width:350px;
object-fit-cover;
background-position:center;
// box-shadow: 0 0 40px rgb(0 0 0 / 40%);
border-radius:6px;

`