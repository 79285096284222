import styled from 'styled-components';

export const HomeContainer = styled.div`
position: absolute;
top: 0;
right: 0;
left: 0;
bottom: 0;
margin: 10px;
`
export const TextWrap = styled.div`
position: relative;
top:40%;
}

`
export const Heading = styled.div`
font-size: 40px;
transform: translateY(56px);
text-align:center;
font-weight:700;
animation: 1s cubic-bezier(0, 0.1, 0.12, 0.99) 0s 1 normal forwards running slideInFromBottom;
@keyframes slideInFromBottom {
  0% {
    transform: translateY(40px);
}
100% {
    transform: translateY(0px);
}
`



