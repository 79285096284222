import styled from "styled-components";
import { Link } from "react-router-dom";
export const ToggleButtonNavigation = styled.div`
//   margin-top:100px;
//   position: relative;
//   display:flex;
`
export const LabelLeft = styled(Link)`
position: absolute;
top:50%;
left: 3%;
@media(max-width:767px){
    top:70%;
    left:0;
    font-size:2.4rem;
}
cursor: pointer;
font-size:3.4rem;
text-decoration:none;
color:#000;
z-index:10;
&:hover{
    color:#000; 
}
`
export const LabelRight = styled(Link)`
position: absolute;
right: 3%;
top:50%;
@media(max-width:767px){
    top:70%;
    right:0;
    font-size:2.4rem;
}
cursor:pointer;
font-size:3.4rem;
text-decoration:none;
color:#000;
z-index:10;
&:hover{
    color:#000; 
}
`
export const Text = styled.span`
font-size:1rem;
margin-right:10px;
`