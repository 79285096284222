import React from 'react';
import {
    AboutMeSection,
    Container,
    InnerSection,
    LeftSection,
    AnimationContent,
    AboutImage,
    RightSection,
    MyContent,
    Border
} from './about-section-styles';
import SkillsSet from './skills-section-component';
import Navigation from '../common/common-component';

const AboutSection = () => {
    return (
        <AboutMeSection>
            <div className='container'>
                <InnerSection>
                    <LeftSection className='col-lg-6 col-md-6 col-sm-10'>
                        <AnimationContent>
                            <AboutImage className='mx-auto' src='/about.jpg'></AboutImage>
                        </AnimationContent>
                    </LeftSection>
                    <RightSection>
                        <AnimationContent>
                            <h1>
                                About Me
                            </h1>
                            <Border />
                            <MyContent className='mt-3'>
                                <span>
                                    Full Stack Developer who really cares about quality product deliver.
                                    I like building new stuffs and working on tough projects that as world is evolving right now and getting interest for new things.
                                </span>
                                <br />
                                <br />
                                Here are a few technologies I've been working with recently:
                                <div className='row mt-3'>
                                    <SkillsSet skill='Django Framework/API' icon={<i className="fab fa-python p-2"></i>} />
                                    <SkillsSet skill='Web Scraping Python' icon={<i className="fab fa-python p-2"></i>} />
                                    <SkillsSet skill='Machine Learning' icon={<i className="fab fa-python p-2"></i>} />
                                    <SkillsSet skill='React/Redux' icon={<i className="fab fa-js p-2"></i>} />
                                    <SkillsSet skill='Javascript(ES6 +)/Typescript' icon={<i className="fab fa-js p-2"></i>} />
                                    <SkillsSet skill='Next JS' icon={<i className="fab fa-js p-2"></i>} />
                                </div>
                            </MyContent>
                        </AnimationContent>
                    </RightSection>
                </InnerSection>
                <Navigation
                    previousName='Home'
                    nextName='Projects'
                    prevLink='/'
                    nextLink='/work'
                />
            </div>
        </AboutMeSection>
    )
}

export default AboutSection;