import React from 'react';
import {
    WorkSectionContainer,
    WorkAnimation
} from './work-section.styles';
import WorkCard from './work-card-component';
import Navigation from '../common/common-component';

const WorkSection = () => {
    return (
        <>
            <Navigation
                previousName='About'
                nextName='Contact'
                prevLink='/about'
                nextLink='/contact'
            />
            <WorkAnimation>
                <WorkSectionContainer>
                    <h1 className='text-center w-100 m-5'>Work Projects</h1>
                    <br />
                    <WorkCard
                        title='Bonfire'
                        detail='Automatically generate customer insights.'
                        link='https://www.getbonfire.io/'
                        image={'/bonfire.png'}
                        techList={['OpenAI', 'Javascript', 'Django', 'Html', 'Css/Bootstrap']}
                    />
                    <WorkCard
                        title='LearnSIC - E-Learning'
                        detail={'Learnsic is an online coaching platform that helps students of different programs and levels learn as per their curriculum and prepare for tests and exams.'}
                        link='https://learnsic.com/'
                        image={'/learnsic.png'}
                        techList={['Django', 'Javascript', 'Typescript', 'React/Redux', 'Django-Celery', 'Next JS', 'Antd CSS']}
                    />
                    <WorkCard
                        title='Data Management System'
                        detail='System that uses artificial intelligence to classify category, sub-category and degree level based on course name. It has also auto-scehdule functionality for running selenium and scraping task.'
                        image={'/web-scraping.gif'}
                        techList={['Javascript', 'Machine Learning', 'Django', 'Selenium', 'Scrapy', 'SVM']}
                        link='http://3.108.249.227/'
                    />
                    {/* <WorkCard
                        title='AI ChatBot System'
                        detail='A Beauty Parlor Web application that uses chatbot for autoresponse and dynamic functionliaty where user can book an appointment with the salon.'
                        image={'/beauty.gif'}
                        techList={['Javascript', 'Deep Learning', 'Django', 'Tensorflow', 'Html', 'Css/Bootstrap']}
                    /> */}
                    <WorkCard
                        title='Zenex - Ecommerce'
                        detail='An Online Electronic Store For Buying Products.'
                        link='http://zenex.com.np/'
                        image={'/zenex.png'}
                        techList={['Javascript', 'Django', 'Html', 'Css/Bootstrap']}
                    />
                    <h1 className='text-center w-100 m-5'>Other NoteWorthy Projects</h1>
                    <br />
                    <WorkCard
                        title='KTM Real Estate'
                        detail='Final Year Project that has spam filtering system as a AI part in it and has a real estate website functionlity with interactive UI.'
                        link='http://ktmrealestate.herokuapp.com/'
                        image={'/ktm.png'}
                        techList={['Javascript', 'Machine Learning', 'Django', 'Naive Bayes', 'Html', 'Css/Bootstrap']}
                    />
                    <WorkCard
                        title='Plant Disease Classification'
                        detail='This is article which uses Deep Learning technique to classify disease from plants leaves which has over 99% accuracy.'
                        link='https://medium.com/@g.kuikel/plants-disease-classification-using-deep-learning-and-making-web-app-in-django-d7b56d3ee972'
                        image={'/plant disease.gif'}
                        techList={['Medium-Article', 'Javascript', 'Deep Learning', 'Image Recognition', 'Html', 'Css', 'CNN', 'Django', 'Tensorflow']}
                    />
                    <WorkCard
                        title='Data-Visualization-India'
                        detail='A Jupyter File for visualizing large amount of data using pandas and also handling data with Big data tools like pyspark, hadoop.'
                        link='https://github.com/ganeshkuikel/Data-Visualization-India-2001-2011/blob/master/indian%20dataset.ipynb'
                        image={'/visualize.png'}
                        techList={['Python/Jupyter Notebook', 'Pandas', 'Hadoop/Big-Data', 'Apache-Pyspark', 'Oracle']}
                    />
                </WorkSectionContainer>
            </WorkAnimation>
        </>
    )
};
export default WorkSection;