import React, { useState } from 'react';
import Particles from 'react-particles-js';
import ParticleConfig from '../../config/particle-config'
import {
    HomeContainer,
    TextWrap,
    Heading
} from './home-section.styles';
import Navigation from '../common/common-component';
import { Button } from '../header-section/header.styles';

const HomeSection = () => {
    return (
        <>
            <Particles params={ParticleConfig} />
            <HomeContainer>
                <TextWrap>
                    <Heading>
                        Hi I am Ganesh Kuikel.
                        I am a Full-Stack Developer.
                        <br />
                        <Button to="/work">
                            Explore
                        </Button>
                        <Navigation 
                            previousName='Contact'
                            nextName='About'
                            prevLink='/contact'
                            nextLink='/about'
                        />
                    </Heading>
                </TextWrap>
            </HomeContainer>
        </>
    )
};

export default HomeSection;